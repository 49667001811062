<template>
    <div>
        <div class="wrapper">
        <!-- header -->
        <!-- 大網展開menu: header-menu-sec 移除hidden -->
        <!-- 小網展開menu: wrapper 加class="header-nav-open" -->
        <header>
        <div class="pagesize">
            <div class="header-area">
            <h1><router-link to="/"><span class="zh">士林區區域電能管理系統</span><br /><span class="eng">SHILIN Community Energy Management
                System </span></router-link></h1>
            <p class="header-time">更新時間：{{updateTime}}</p>
            <a href="#" class="header-nav-btn"></a>
            <div class="header-nav">
                <a href="./login" class="btn-login" @click="LOGIN_OUT">登出</a>
                <ul class="header-menu-first">
                  <li><router-link to="/">能源地圖</router-link></li>
                  <li><router-link to="/electricity" class="on">發用電資訊</router-link></li>
                  <li><a href="#"  class="folder">需量反應</a>
                    <ul class="header-menu-sec">
                      <li><router-link to="./bidCalc">投標試算</router-link></li>
                      <li><router-link to="./bidRecord">得標及抑低分配發布</router-link></li>
                      <li><router-link to="./realTime">即時執行情形</router-link></li>
                      <li><router-link to="./history">歷史紀錄</router-link></li>
                      <li><router-link to="./group">群組成員資料</router-link></li>
                      <li><router-link to="./download">下載專區</router-link></li>
                    </ul>
                  </li>
                </ul>
            </div>
            </div>
        </div>
        </header>
        <!-- container -->
        <div class="container">
        <div class="pagesize">
            <div class="area bg-white margin02">
            <div class="padding03">
                <div class="elec-area">
                <div class="elec-box bg01">
                    <h3>{{exMon}}月平均節電率</h3>
                    <p class="number color-blue-light"><span class="num">{{savingRateTotal}}</span>%</p>
                </div>
                <div class="elec-box bg02">
                    <h3>今日累計太陽能發電量</h3>
                    <p class="number color-blue-light"><span class="num">{{solarKwh}}</span>度電</p>
                </div>
                <div class="elec-box bg03">
                    <h3>總累計減碳量</h3>
                    <p class="number color-blue-light"><span class="num">{{co2ReducTotal}}</span>公斤</p>
                </div>
                <div class="elec-box bg04">
                    <h3>累計儲能抑低尖峰用電量</h3>
                    <p class="number color-blue-light"><span class="num">{{esKwhTotal}}</span>度電</p>
                </div>
                </div>
            </div>
            </div>
            <!-- area -->
            <div class="area bg-white margin02">
            <div class="padding03">
                <h2 class="boxtitle margin02">高壓用戶</h2>
                <ul class="groupInfo-area">
                <li>
                    <div class="groupInfo-title">
                    <p class="f-left">士林區行政中心</p>
                    <p class="f-right"><span class="wifi-on">正常</span></p>
                    <div class="clr"></div>
                    </div>
                    <div class="groupInfo-inner">
                    <div class="dashboardCard">
                        <div class="dashboardCard-img">
                        <h3 class="font-size-l color-kahki margin02">用電需量占比</h3>
                        <div class="SemiCircleBar-box incard">
                            <div class="SemiCircleBar01" id ='SHILIN'></div>
                            <p class="min">0</p>
                            <p class="max">100</p>
                        </div>
                        </div>
                        <div class="dashboardCard-box">
                        <div class="numbox margin">
                            <div class="title">
                            <p>用電需量 (kW)</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachSiteKw('SHILIN_DISTRICT_OFFICE_MERGED', 'METER_PUBLIC')}}</span><span class="font-recursive">kW</span></p>
                            </div>
                        </div>
                        <div class="numbox">
                            <div class="title">
                            <p><span class="color-blue-light weight-bold">{{exMon}}月</span>節電率</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num" v-if="electricitySum.data">{{eachSavingRate("shilinDistrict")}}</span><span class="font-recursive">%</span></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </li>
                <li v-for="(x, idx) in highOptionFilter" :key="idx">
                    <div class="groupInfo-title">
                    <p class="f-left">{{x.name}}</p>
                    <p class="f-right"><span class="wifi-on">正常</span></p>
                    <div class="clr"></div>
                    </div>
                    <div class="groupInfo-inner" v-show="true">
                    <div class="dashboardCard">
                        <div class="dashboardCard-img">
                        <h3 class="font-size-l color-kahki margin02">用電需量占比</h3>
                        <div class="SemiCircleBar-box incard">
                            <div :class="`SemiCircleBar${idx + 1}`" :id='x.ems[0]'></div>
                            <p class="min">0</p>
                            <p class="max">100</p>
                        </div>
                        </div>
                        <div class="dashboardCard-box">
                        <div class="numbox margin">
                            <div class="title">
                            <p>用電需量 (kW)</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachSiteKw(x.ems[0], 'METER_1')}}</span><span class="font-recursive">kW</span></p>
                            </div>
                        </div>
                        <div class="numbox">
                            <div class="title">
                            <p><span class="color-blue-light weight-bold">{{exMon}}月</span>節電率</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachSavingRate(x.ems[0])}}</span><span class="font-recursive">%</span></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <!-- <div style="text-align: center;">建置中</div> -->
                </li>
                </ul>
            </div>
            </div>
            <!-- area -->
            <div class="area bg-white margin02">
            <div class="padding03">
                <h2 class="boxtitle margin02">低壓用戶</h2>
                <ul class="groupInfo-area">
                <li>
                    <div class="groupInfo-title">
                    <p class="f-left">士林區行政中心-B1、B2</p>
                    <p class="f-right">
                      <span class="wifi-on" v-if="eachfloorStatus('METER_B1B2')">正常</span>
                      <span class="wifi-off" v-else>斷線</span>
                    </p>
                    <div class="clr"></div>
                    </div>
                    <div class="groupInfo-inner">
                    <div class="dashboardCard">
                        <div class="dashboardCard-box">
                        <div class="numbox">
                            <div class="title">
                            <p>即時用電量</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachfloorKw('METER_B1B2')}}</span><span class="font-recursive">kW</span></p>
                            </div>
                        </div>
                        </div>
                        <div class="dashboardCard-box">
                        <div class="numbox">
                            <div class="title">
                            <p><span class="color-blue-light weight-bold">{{exMon}}月</span>節電率</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachFloorSavingRate('METER_B1B2')}}</span><span class="font-recursive">%</span></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </li>
                <li>
                    <div class="groupInfo-title">
                    <p class="f-left">士林區行政中心-7F</p>
                    <p class="f-right">
                      <span class="wifi-on" v-if="eachfloorStatus('METER_7F')">正常</span>
                      <span class="wifi-off" v-else>斷線</span>
                    </p>
                    <div class="clr"></div>
                    </div>
                    <div class="groupInfo-inner">
                    <div class="dashboardCard">
                        <div class="dashboardCard-box">
                        <div class="numbox">
                            <div class="title">
                            <p>即時用電量</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachfloorKw('METER_7F')}}</span><span class="font-recursive">kW</span></p>
                            </div>
                        </div>
                        </div>
                        <div class="dashboardCard-box">
                        <div class="numbox">
                            <div class="title">
                            <p><span class="color-blue-light weight-bold">{{exMon}}月</span>節電率</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachFloorSavingRate('METER_7F')}}</span><span class="font-recursive">%</span></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </li>
                <li>
                    <div class="groupInfo-title">
                    <p class="f-left">士林區行政中心-8F</p>
                    <p class="f-right">
                      <span class="wifi-on" v-if="eachfloorStatus('METER_8F')">正常</span>
                      <span class="wifi-off" v-else>斷線</span>
                    </p>
                    <div class="clr"></div>
                    </div>
                    <div class="groupInfo-inner">
                    <div class="dashboardCard">
                        <div class="dashboardCard-box">
                        <div class="numbox">
                            <div class="title">
                            <p>即時用電量</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachfloorKw('METER_8F')}}</span><span class="font-recursive">kW</span></p>
                            </div>
                        </div>
                        </div>
                        <div class="dashboardCard-box">
                        <div class="numbox">
                            <div class="title">
                            <p><span class="color-blue-light weight-bold">{{exMon}}月</span>節電率</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachFloorSavingRate('METER_8F')}}</span><span class="font-recursive">%</span></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </li>
                <li>
                    <div class="groupInfo-title">
                    <p class="f-left">士林區行政中心-9F</p>
                    <p class="f-right">
                      <span class="wifi-on" v-if="eachfloorStatus('METER_9F')">正常</span>
                      <span class="wifi-off" v-else>斷線</span>
                    </p>
                    <div class="clr"></div>
                    </div>
                    <div class="groupInfo-inner">
                    <div class="dashboardCard">
                        <div class="dashboardCard-box">
                        <div class="numbox">
                            <div class="title">
                            <p>即時用電量</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachfloorKw('METER_9F')}}</span><span class="font-recursive">kW</span></p>
                            </div>
                        </div>
                        </div>
                        <div class="dashboardCard-box">
                        <div class="numbox">
                            <div class="title">
                            <p><span class="color-blue-light weight-bold">{{exMon}}月</span>節電率</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachFloorSavingRate('METER_9F')}}</span><span class="font-recursive">%</span></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </li>
                <li>
                    <div class="groupInfo-title">
                    <p class="f-left">士林區行政中心-10F</p>
                    <p class="f-right">
                      <span class="wifi-on" v-if="eachfloorStatus('METER_10F')">正常</span>
                      <span class="wifi-off" v-else>斷線</span>
                    </p>
                    <div class="clr"></div>
                    </div>
                    <div class="groupInfo-inner">
                    <div class="dashboardCard">
                        <div class="dashboardCard-box">
                        <div class="numbox">
                            <div class="title">
                            <p>即時用電量</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachfloorKw('METER_10F')}}</span><span class="font-recursive">kW</span></p>
                            </div>
                        </div>
                        </div>
                        <div class="dashboardCard-box">
                        <div class="numbox">
                            <div class="title">
                            <p><span class="color-blue-light weight-bold">{{exMon}}月</span>節電率</p>
                            </div>
                            <div class="inner number">
                            <p><span class="num">{{eachFloorSavingRate('METER_10F')}}</span><span class="font-recursive">%</span></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </li>
                </ul>
            </div>
            </div>
            <!-- area -->
            <div class="area bg-white margin02">
            <div class="padding03">
                <h2 class="boxtitle margin02">資料查詢</h2>
                <!-- input -->
                <div class="graphCheck-area a-center margin03">
                <div class="inner">
                    <div class="form-line margin0">
                    <div class="form-item">
                        <select class="w-l" v-model="selectUser" @change="setSelectSite(selectUser)">
                          <option value="low">低壓用戶</option>
                          <option value="high">高壓用戶</option>
                        </select>
                        <select class="w-xl" v-model="selectSite">
                          <option v-for="(i, idx) in optionSet(selectUser)" :value="i.ems" :key="idx">{{i.name}}</option>
                        </select>
                    </div>
                    <div class="form-item">
                        <div class="inputbtn w-l">
                            <date-picker style="width:auto" placeholder="起始日期" v-model="beginDate" valueType="format" :disabled-date='disabledDate'></date-picker>
                        <!-- <input type="text" value="2021/03/10">
                        <button class="date"></button> -->
                        </div>
                        <!-- <span>&nbsp;~&nbsp;</span>
                        <div class="inputbtn w-l">
                        <input type="text" value="2021/03/10">
                        <button class="date"></button>
                        </div> -->
                    </div>
                    </div>
                    <div class="mobile margin"></div>
                </div>
                <div class="btnbox">
                  <!-- <loading :active.sync="isLoading"
                    :can-cancel="true"
                    :on-cancel="onCancel"
                    :is-full-page="fullPage">
                  </loading> -->
                  <a class="btn-border" @click="getChart">查詢</a>
                </div>
                </div>
                <h3 class="icontitle icon01"><span>節約電力</span></h3>
                <!-- <div class="vld-parent">
                                  <loading :active.sync="isLoading"
                    :can-cancel="true"
                    :is-full-page="fullPage">
                  </loading>
                </div> -->
                <!-- 圖表 -->
                <p class="f-left color-blue-light weight-bold" v-if="selectUser === 'high'">即時用電需量(kW)</p>
                <p class="f-right tR" v-if="selectUser === 'high'">
                <!-- <span class="colorLine color-blue-light">昨日用電量</span> -->
                <!-- <span class="colorLine color-green margin-left">今日用電量</span> -->
                  <!-- <Highcharts :options="query15DmdChart" ref="highchart" /> -->
                  <!-- <Highcharts :options="query15DmdChart" ref="highchart" /> -->
                  <!-- <Highcharts :options="query15DmdChart" ref="highchart" /> -->
                </p>
                <div class="clr margin"></div>
                <div class="imgbox full margin" v-if="selectUser === 'high'">
                  <Highcharts :options="query15DmdChart" ref="highchart" />
                <!-- <img src="./img/graph04.jpg"> -->
                </div>
                <!-- 圖表 -->
                <p class="f-left color-blue-light weight-bold">用電量(度)</p>
                <p class="f-right tR">
                <!-- <span class="colorLine color-blue-light">昨日用電量</span>
                <span class="colorLine color-green margin-left">今日用電量</span> -->
                </p>
                <div class="clr margin"></div>
                <div class="imgbox full margin">
                  <Highcharts :options="accEtodayChart" ref="highchart" />
                <!-- <img src="./img/graph05.jpg"> -->
                </div>
                <h3 class="icontitle icon02" v-if="selectUser === 'high'"><span>再生能源</span></h3>
                <h4 class="tagtitle font-size-l margin02" v-if="selectUser === 'high'"><span>太陽光電</span></h4>
                <div class="flex-area m-block margin03" v-if="selectUser === 'high'">
                <div class="flex-box">
                    <p class="color-blue-light weight-bold">本日發電量 (度)</p>
                    <div class="imgbox full">
                        <Highcharts :options="solarKwhChart" ref="highchart" />
                    <!-- <img src="./img/graph06.jpg"> -->
                    </div>
                </div>
                <div class="flex-box">
                    <p class="color-blue-light weight-bold">累積減碳量 (公斤)</p>
                    <div class="imgbox full">
                        <Highcharts :options="solarCo2Chart" ref="highchart" />
                    <!-- <img src="./img/graph07.jpg"> -->
                    </div>
                </div>
                </div>
                <h3 class="icontitle icon03" v-if="selectUser === 'high'" v-show="true"><span>儲能電池</span></h3>
                <div class="flex-area m-block margin03" v-if="selectUser === 'high'" v-show="true">
                <div class="flex-box">
                    <p class="color-blue-light weight-bold">今日充放電功率(kW)</p>
                    <div class="imgbox full">
                        <Highcharts :options="chargeDischargeChart" ref="highchart" />
                    <!-- <img src="./img/graph08.jpg"> -->
                    </div>
                </div>
                <div class="flex-box">
                    <p class="color-blue-light weight-bold">電池剩餘容量(%)</p>
                    <div class="imgbox full">
                        <Highcharts :options="batteryChart" ref="highchart" />
                    <!-- <img src="./img/graph09.jpg"> -->
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        <!--  footer -->
        <footer>
        <div class="pagesize">
            <div class="footer-info">
            <div class="info-box info-main">
                <div class="logo"></div>
                <div class="copyright">
                <p><a href="#">政府網站資料開放宣告</a>&nbsp;|&nbsp;<a href="#">隱私權及網站安全政策</a></p>
                <p>Copyright © All Rights Reserved</p>
                </div>
            </div>
            <div class="info-box info-sub">
                <p>執行單位：(02)2586-5000 # 325 陳小姐</p>
                <div class="logo02"></div>
            </div>
            </div>
        </div>
        </footer>
    </div>
    </div>
</template>

<style scoped>
    @import './css/inner.css';
</style>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { dbDataHandle } from '../store/function/index'
import importJQ from './js/main'
import ProgressBar from './js/jquery.progressbar'
import DatePicker from 'vue2-datepicker'
import cookies from 'vue-cookies'
import 'vue2-datepicker/index.css'
import { DateTime } from 'luxon'
import axios from 'axios'
export default {
  components: { DatePicker },
  data () {
    return {
      updateTime: DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
      acCO2: '',
      query15Dmd: '',
      selectUser: 'high',
      savingRateTotal: '',
      pvTodayTotal: '',
      co2ReducTotal: '',
      esKwhTotal: 0,
      floorSavingRateTotal: '',
      electricitySum: '',
      CurrentKw: '',
      lowMeterKw: '',
      exMon: DateTime.local().minus({ months: 1 }).toFormat('MM'),
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      selectSite: ['SHILIN', 'shilinDistrict'],
      queryKwByHour: '',
      selectOption: [],
      essMindata: [],
      lowOption: [
        {
          ems: ['METER_B1B2', 'shilinDistrict'],
          name: '士林區行政中心-B1、B2'
        },
        {
          ems: ['METER_7F', 'shilinDistrict'],
          name: '士林區行政中心-7F'
        },
        {
          ems: ['METER_8F', 'shilinDistrict'],
          name: '士林區行政中心-8F'
        },
        {
          ems: ['METER_9F', 'shilinDistrict'],
          name: '士林區行政中心-9F'
        },
        {
          ems: ['METER_10F', 'shilinDistrict'],
          name: '士林區行政中心-10F'
        }
      ],
      highOption: [
        {
          ems: ['SHILIN', 'shilinDistrict'],
          name: '士林區行政中心'
        },
        {
          ems: ['TAIPEI_ASTRONOMICAL_MUSEUM', 'TAIPEI_ASTRONOMICAL_MUSEUM'],
          name: '天文科學教育館'
        },
        {
          ems: ['SHILIN_MARKET', 'SHILIN_MARKET'],
          name: '士林市場'
        },
        {
          ems: ['SHILIN_PRECINCT_POLICE_STATION', 'SHILIN_PRECINCT_POLICE_STATION'],
          name: '士林分局'
        },
        {
          ems: ['SHILIN_SPORTS_CENTER', 'SHILIN_SPORTS_CENTER'],
          name: '士林運動中心'
        },
        {
          ems: ['SHIDONG_MARKET', 'SHIDONG_MARKET'],
          name: '士東市場'
        }
      ],
      query15DmdChart: {
        chart: {
          type: 'line',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        tooltip: {
          shared: true
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [2, 6]
        },
        yAxis: [
          {
            title: {
              text: ''
            },
            min: 0
          },
          {
            title: {
              text: ''
            },
            min: 0
          }
        ],
        series: [{
          type: 'area',
          name: '昨日用電需量',
          data: [],
          color: '#a3dce3'
        },
        {
          type: 'area',
          name: '本日用電需量',
          data: [],
          color: '#bbe67d'
        },
        {
          yAxis: 0,
          type: 'spline',
          name: '契約容量',
          data: [],
          color: 'red'
        }
        ],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
        // , {
        //   // name: 'John',
        //   // data: [5, 7, 3]
        // }]
      },
      accEtodayChart: {
        chart: {
          type: 'column',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [2, 6]
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        series: [{
          name: '昨日用電量',
          data: [],
          color: '#a3dce3'
        },
        {
          name: '今日用電量',
          data: [],
          color: '#bbe67d'
        }
        ],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
        // , {
        //   // name: 'John',
        //   // data: [5, 7, 3]
        // }]
      },
      solarKwhChart: {
        chart: {
          type: 'column',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [2, 6]
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'center',
          layout: 'horizontal',
          // floating: true,
          verticalAlign: 'bottom',
          x: 0,
          y: 0
        },
        series: [{
          name: '本日發電量 (度)',
          data: [],
          color: '#bbe67d'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
        // , {
        //   // name: 'John',
        //   // data: [5, 7, 3]
        // }]
      },
      solarCo2Chart: {
        chart: {
          type: 'area',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'center',
          layout: 'horizontal',
          // floating: true,
          verticalAlign: 'bottom',
          x: 0,
          y: 0
        },
        series: [{
          name: '累積減碳量 (公斤)',
          data: [],
          color: '#a3dce3'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
        // , {
        //   // name: 'John',
        //   // data: [5, 7, 3]
        // }]
      },
      chargeDischargeChart: {
        chart: {
          type: 'area',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [2, 6]
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        series: [{
          name: '今日充放電功率(kW)',
          data: [2, -1],
          color: '#a3dce3'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
        // , {
        //   // name: 'John',
        //   // data: [5, 7, 3]
        // }]
      },
      batteryChart: {
        chart: {
          type: 'area',
          backgroundColor: null,
          height: 280
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [2, 6]
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        series: [{
          name: '電池剩餘容量(%)',
          data: [2, 5],
          color: '#a3dce3'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                // align: 'center',
                // verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
        // , {
        //   // name: 'John',
        //   // data: [5, 7, 3]
        // }]
      },
      downLoadArrayColumns: '',
      downLoadArrayData: ''
    }
  },
  methods: {
    ...mapActions([
      'getSolarKwToday',
      'getContractRate'
    ]),
    ...mapMutations([
      'LOGIN_OUT'
    ]),
    disabledDate (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date > new Date(today.getTime())
    },
    dbDataHandle (num, index) {
      return dbDataHandle(num, index)
    },
    optionSet (set) {
      if (set === 'high') {
        this.selectOption = this.highOption
      }
      if (set === 'low') {
        this.selectOption = this.lowOption
      }
      return this.selectOption
    },
    setSelectSite (set) {
      if (set === 'high') {
        this.selectSite = ['SHILIN', 'shilinDistrict']
        this.QueryKwByHour()
      }
      if (set === 'low') {
        this.selectSite = ['METER_B1B2', 'shilinDistrict']
        this.QueryKwByHour(this.selectSite[0])
      }
    },
    eachSavingRate (emsSite) {
      if (this.electricitySum.data) {
        const savingRate = this.electricitySum.data.find(x => x.ems === emsSite)
        if (savingRate) return this.dbDataHandle(savingRate.data.publicSavingRate)
      }
    },
    eachFloorSavingRate (floor) {
      if (this.electricitySum.data) {
        for (let i = 0; i < this.electricitySum.data.length; i++) {
          for (let j = 0; j < this.electricitySum.data[i].data.floorSavingRate.length; j++) {
            if (this.electricitySum.data[i].data.floorSavingRate[j].floor === floor) {
              return this.dbDataHandle(this.electricitySum.data[i].data.floorSavingRate[j].save)
            }
          }
        }
      }
    },
    eachSiteKw (ems, meter) {
      for (let i = 0; i < this.CurrentKw.length; i++) {
        if (this.CurrentKw[i].ems === ems) {
          for (let j = 0; j < this.CurrentKw[i].detail.METER.length; j++) {
            if (this.CurrentKw[i].detail.METER[j].deviceKey === meter) {
              return this.CurrentKw[i].detail.METER[j].kW
            }
          }
        }
      }
    },
    eachfloorKw (floor) {
      if (this.lowMeterKw.data) {
        const whichFloor = this.lowMeterKw.data.find(x => x.floor === floor)
        if (!whichFloor) return 0

        return this.dbDataHandle(whichFloor.kW)
      }
    },
    eachfloorStatus (floor) {
      if (this.lowMeterKw.data) {
        const whichFloor = this.lowMeterKw.data.find(x => x.floor === floor)
        if (!whichFloor) return 0
        const status = whichFloor.status === '正常' ? 1 : 0

        return status
      }
    },
    setchartTime () {
      this.query15DmdChart.xAxis.categories = []
      this.query15DmdChart.series[0].data = []
      let time = DateTime.fromObject({ hour: 0, minute: 0, second: 0, zone: 'Asia/Taipei' })
      for (let i = 0; i < 1440; i++) {
        time = time.plus({ minute: 1 })
        this.query15DmdChart.xAxis.categories.push(time.toFormat('HH:mm'))
        this.query15DmdChart.series[0].data[i] = 0
      }
    },
    async progressBar () {
    //   var barValue = 82; //設定數值顯示文字
      await this.getContractRate()
      for (let i = 0; i < this.contractRate.length; i++) {
        const site = `#${this.contractRate[i].ems}`
        const chartArr = []
        const value = this.dbDataHandle(this.contractRate[i].data * 100)
        chartArr[i] = new ProgressBar.SemiCircle(site, {
          strokeWidth: 6,
          color: '#3fc3d1',
          trailColor: '#e1dfeb',
          trailWidth: 6,
          easing: 'easeInOut',
          duration: 1000,
          svgStyle: null,
          text: {
            value: value
          },
          step: function (state, circle) {
            circle.setText(value)
          }
        })
        chartArr[i].animate(value / 100)
      }
      // fake data
      // const fakeData = [55, 63, 47]
      // for (let i = 0; i < fakeData.length; i++) {
      //   const site = `#fakeData${i}`
      //   const chartArr = []
      //   const value = fakeData[i]
      //   chartArr[i] = new ProgressBar.SemiCircle(site, {
      //     strokeWidth: 6,
      //     color: '#3fc3d1',
      //     trailColor: '#e1dfeb',
      //     trailWidth: 6,
      //     easing: 'easeInOut',
      //     duration: 1000,
      //     svgStyle: null,
      //     text: {
      //       value: value
      //     },
      //     step: function (state, circle) {
      //       circle.setText(value)
      //     }
      //   })
      //   chartArr[i].animate(value / 100)
      // }
    },
    async getFourValue () {
      if (!this.electricitySum.data) {
        await this.getElectricitySum()
      }
      await this.getAcCO2()
      if (this.acCO2.data) {
        this.co2ReducTotal = this.dbDataHandle(this.acCO2.data[this.acCO2.data.length - 1].acCo2)
      } else {
        this.co2ReducTotal = 0
      }
      // this.co2ReducTotal = 0
      this.pvTodayTotal = 0
      this.savingRateTotal = 0
      this.floorSavingRateTotal = 0
      if (this.electricitySum.success) {
        for (let i = 0; i < this.electricitySum.data.length; i++) {
          // this.co2ReducTotal = this.co2ReducTotal + this.electricitySum.data[i].data.co2Reduc
          this.pvTodayTotal = this.pvTodayTotal + this.electricitySum.data[i].data.pvToday
          this.savingRateTotal = this.savingRateTotal + this.electricitySum.data[i].data.publicSavingRate
          for (let j = 0; j < this.electricitySum.data[i].data.floorSavingRate.length; j++) {
            this.floorSavingRateTotal = this.floorSavingRateTotal + this.electricitySum.data[i].data.floorSavingRate[j].save
          }
        }
        // this.co2ReducTotal = this.dbDataHandle(this.co2ReducTotal)
        this.pvTodayTotal = this.dbDataHandle(this.pvTodayTotal)
        this.savingRateTotal = this.dbDataHandle((this.savingRateTotal + this.floorSavingRateTotal) / this.electricitySum.data.length)
      }
    },
    async getChart () {
      if (this.selectUser === 'high') {
        this.QueryKwByHour()
        this.Query15DmdChart()
        this.getAllMin()
      } else {
        this.QueryKwByHour(this.selectSite[0])
      }
    },
    async Query15DmdChart () {
      await this.getQuery15Dmd()
      if (this.query15Dmd.success) {
        let cap = 0
        if (this.selectSite[1] === 'shilinDistrict') cap = 376
        if (this.selectSite[1] === 'TAIPEI_ASTRONOMICAL_MUSEUM') cap = 1300
        if (this.selectSite[1] === 'SHILIN_MARKET') cap = 840
        if (this.selectSite[1] === 'SHILIN_PRECINCT_POLICE_STATION') cap = 359
        if (this.selectSite[1] === 'SHIDONG_MARKET') cap = 1050
        if (this.selectSite[1] === 'SHILIN_SPORTS_CENTER') cap = 450
        //
        // 即時用電需量(kW)
        this.query15DmdChart.xAxis.categories = []
        this.query15DmdChart.series[0].data = []
        this.query15DmdChart.series[1].data = []
        this.query15DmdChart.series[2].data = []
        let time = DateTime.fromObject({ hour: 0, minute: 0, second: 0, zone: 'Asia/Taipei' })
        for (let i = 0; i <= 1440; i++) {
          this.query15DmdChart.xAxis.categories.push(time.toFormat('HH:mm'))
          this.query15DmdChart.series[0].data[i] = null
          this.query15DmdChart.series[1].data[i] = null
          this.query15DmdChart.series[2].data[i] = cap
          time = time.plus({ minute: 1 })
        }
        const yesterdayTimeArr = this.query15Dmd.data.yesterday.map(x => DateTime.fromSeconds(x.timestamp).toFormat('HH:mm'))
        const todayTimeArr = this.query15Dmd.data.today.map(x => DateTime.fromSeconds(x.timestamp).toFormat('HH:mm'))
        for (let i = 0; i < this.query15Dmd.data.yesterday.length; i++) {
          const idx = this.query15DmdChart.xAxis.categories.findIndex(x => x === yesterdayTimeArr[i])
          if (idx >= 0 && this.query15Dmd.data.yesterday[i]) {
            this.query15DmdChart.series[0].data[idx] = this.dbDataHandle(this.query15Dmd.data.yesterday[i].demand_15)
          }
        }
        for (let i = 0; i < this.query15Dmd.data.today.length; i++) {
          const idx = this.query15DmdChart.xAxis.categories.findIndex(x => x === todayTimeArr[i])
          if (idx >= 0 && this.query15Dmd.data.today[i]) {
            this.query15DmdChart.series[1].data[idx] = this.dbDataHandle(this.query15Dmd.data.today[i].demand_15)
          }
        }
      }
    },
    async QueryKwByHour (floor) {
      await this.getqueryKwByHour()
      await this.getAcCO2()
      if (this.queryKwByHour.success) {
        this.accEtodayChart.xAxis.categories = []
        this.accEtodayChart.series[0].data = []
        this.accEtodayChart.series[1].data = []
        this.solarCo2Chart.xAxis.categories = []
        this.solarCo2Chart.series[0].data = []
        this.solarKwhChart.xAxis.categories = []
        this.solarKwhChart.series[0].data = []
        let time = DateTime.fromObject({ hour: 0, minute: 0, second: 0, zone: 'Asia/Taipei' })
        for (let i = 0; i < 24; i++) {
          this.solarCo2Chart.xAxis.categories.push(time.toFormat('HH:mm'))
          this.solarCo2Chart.series[0].data.push(null)
          this.accEtodayChart.xAxis.categories.push(time.toFormat('HH:mm'))
          this.accEtodayChart.series[0].data.push(null)
          time = time.plus({ hour: 1 })
        }
        // 低壓樓層 資料查詢
        if (floor) {
          for (let i = 0; i < this.queryKwByHour.data.yesterday.length; i++) {
            const idx = this.accEtodayChart.xAxis.categories.findIndex(x => x === this.queryKwByHour.data.yesterday[i].hhmm)
            if (idx >= 0 && this.queryKwByHour.data.yesterday[i]) {
              const yesterdaykWh = this.queryKwByHour.data.yesterday[i].detail.floor.find(x => x.floor === floor) === undefined ? 0 : this.queryKwByHour.data.yesterday[i].detail.floor.find(x => x.floor === floor).kWh
              this.accEtodayChart.series[0].data[idx] = yesterdaykWh
            }
          }
          for (let i = 0; i < this.queryKwByHour.data.today.length; i++) {
            const idx = this.accEtodayChart.xAxis.categories.findIndex(x => x === this.queryKwByHour.data.today[i].hhmm)
            if (idx >= 0 && this.queryKwByHour.data.today[i]) {
              const todaykWh = this.queryKwByHour.data.today[i].detail.floor.find(x => x.floor === floor) === undefined ? 0 : this.queryKwByHour.data.today[i].detail.floor.find(x => x.floor === floor).kWh
              this.accEtodayChart.series[1].data[idx] = todaykWh
            }
          }
        } else {
          for (let i = 0; i < this.queryKwByHour.data.yesterday.length; i++) { // 太陽光電
            this.solarKwhChart.xAxis.categories.push(this.queryKwByHour.data.yesterday[i].hhmm)
            if (this.queryKwByHour.data.today[i]) {
              this.solarKwhChart.series[0].data.push(this.dbDataHandle(this.queryKwByHour.data.today[i].detail.public.pv))
            } else {
              this.solarKwhChart.series[0].data.push(null)
            }
          }
          // 用電量(度)
          for (let i = 0; i < this.queryKwByHour.data.yesterday.length; i++) {
            const idx = this.accEtodayChart.xAxis.categories.findIndex(x => x === this.queryKwByHour.data.yesterday[i].hhmm)
            if (idx >= 0 && this.queryKwByHour.data.yesterday[i]) {
              this.accEtodayChart.series[0].data[idx] = this.dbDataHandle(this.queryKwByHour.data.yesterday[i].detail.public.meter)
            }
          }
          for (let i = 0; i < this.queryKwByHour.data.today.length; i++) {
            const idx = this.accEtodayChart.xAxis.categories.findIndex(x => x === this.queryKwByHour.data.today[i].hhmm)
            if (idx >= 0 && this.queryKwByHour.data.today[i]) {
              this.accEtodayChart.series[1].data[idx] = this.dbDataHandle(this.queryKwByHour.data.today[i].detail.public.meter)
            }
          }
          //
          // 累積減碳量
          if (this.acCO2.data) {
            for (let i = 0; i < this.acCO2.data.length; i++) {
              const idx = this.solarCo2Chart.xAxis.categories.findIndex(x => x === this.acCO2.data[i].hhmm)
              if (idx >= 0 && this.acCO2.data[i]) {
                this.solarCo2Chart.series[0].data[idx] = this.dbDataHandle(this.acCO2.data[i].acCo2)
              }
            }
            this.solarCo2Chart.yAxis.min = this.dbDataHandle(this.acCO2.data[0].acCo2)
          }
        }
      }
    },
    essMinChart () {
      this.chargeDischargeChart.xAxis.categories = []
      this.batteryChart.xAxis.categories = []
      this.chargeDischargeChart.series[0].data = []
      this.batteryChart.series[0].data = []
      this.essMindata.forEach(x => {
        const time = DateTime.fromSeconds(x.timestamp).toFormat('HH:mm')
        this.chargeDischargeChart.xAxis.categories.push(time)
        this.batteryChart.xAxis.categories.push(time)
        if (x.detail.ESS && x.detail.ESS.length) {
          this.chargeDischargeChart.series[0].data.push(x.detail.ESS[0].power)
          this.batteryChart.series[0].data.push(x.detail.ESS[0].soc)
        }
      })
    },
    async getElectricitySum () {
      const token = cookies.get('Authorization')
      const electricitySum = await axios.post('/api/cems/electricitySum',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      this.electricitySum = electricitySum.data
    },
    async getCurrentKw () {
      const token = cookies.get('Authorization')
      const CurrentKw = await axios.post('/api/cems/electricityMin',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      this.CurrentKw = CurrentKw.data
      this.esKwhTotal = 0 // 累計儲能抑低尖峰用電量
      this.CurrentKw.forEach(x => {
        if (x.esKwh) this.esKwhTotal = this.esKwhTotal + x.esKwh
      })
    },
    async getlowMeterKw () {
      const token = cookies.get('Authorization')
      const lowMeterKw = await axios.post('/api/cems/lowMeterKw',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      this.lowMeterKw = lowMeterKw.data
    },
    async getQuery15Dmd () {
      const token = cookies.get('Authorization')
      const query15Dmd = await axios.post('/api/cems/query15Dmd',
        {
          queryDate: this.beginDate,
          ems: this.selectSite[0]
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      this.query15Dmd = query15Dmd.data
    },
    async getqueryKwByHour () {
      const token = cookies.get('Authorization')
      const queryKwByHour = await axios.post('/api/cems/queryKwByHour',
        {
          queryDate: this.beginDate,
          ems: this.selectSite[1]
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      this.queryKwByHour = queryKwByHour.data
    },
    async getAcCO2 () {
      const token = cookies.get('Authorization')
      const acCO2 = await axios.post('/api/cems/acCO2',
        {
          queryDate: this.beginDate,
          emsName: this.selectSite[0]
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (acCO2.data.success) {
        this.acCO2 = acCO2.data
      }
    },
    async getAllMin () {
      const token = cookies.get('Authorization')
      const allMin = await axios.post('/api/cems/getallmin',
        {
          date: this.beginDate,
          emsKey: this.selectSite[0]
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (allMin.data.success) {
        this.essMindata = allMin.data.data
        this.essMinChart()
      }
    }
  },
  async created () {
    await this.getElectricitySum()
    this.getFourValue()
    this.getCurrentKw()
    this.getlowMeterKw()
  },
  async mounted () {
    importJQ()
    // this.setchartTime()
    this.progressBar()
    this.getSolarKwToday()
    this.getChart()
  },
  computed: {
    ...mapState([
      'contractRate',
      'solarKwh'
    ]),
    highOptionFilter () {
      return this.highOption.filter(x => x.name !== '士林區行政中心')
    }
  }
}
</script>
